import Right from 'assets/icons/Right';
import Button from 'common/components/Button';
import Divider from 'common/components/Divider';
import { OnboardingStatus } from 'common/enums';
import useNavigate from 'common/hooks/useNavigate';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import { selectCurrentCompany, setCurrentCompany } from 'common/slices/auth.slice';
import { useUpdateCompanyMutation } from 'common/slices/companyApi.slice';
import { cn } from 'common/utils';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';



import TeamTable from './components/TeamTable';
import DataSettingsForm from './forms/DataSettingsForm';
import DescriptionSettingsForm from './forms/DescriptionSettingsForm';
import IntegrationsSettingsForm from './forms/IntegrationsSettingsForm';
import UserNotificationsForm from './forms/UserNotificationsForm';
import UserSettingsForm from './forms/UserSettingsForm';


const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const [tab, setTab] = useState('advanced');

  const { t } = useTranslation('settings\\index');

  const currentCompany = useSelector(selectCurrentCompany);

  const [updateCompany] = useUpdateCompanyMutation();

  const handleRestartOnboarding = async () => {
    try {
      const data = {
        onboarding_status: OnboardingStatus.restarted,
      };

      const updatedCompany = await updateCompany({
        companyId: currentCompany.id,
        data: data,
      }).unwrap();
      dispatch(setCurrentCompany(updatedCompany));

      navigate('/onboarding');
    } catch (err) {
      toast.newToast('negative', t('toasts.something-wrong'));
    }
  };

  return (
    <div className='flex flex-col h-full w-full max-w-full px-6 pt-6 relative'>
      <div className='flex items-center justify-between mb-4'>
        <h1 className='text-3xl text-dark-liver font-semibold'>
          {t('settings')}
        </h1>

        {currentCompany?.onboarding_status !== OnboardingStatus.done && (
          <Button variant='secondary' onClick={handleRestartOnboarding}>
            {t('restartOnboarding')}
            <Right size='16px' color='#8D91A0' />
          </Button>
        )}
      </div>
      <div className='flex items-center justify-between mb-3.5 z-10'>
        <ul className="flex items-center gap-4">
          <li
            className={cn(
              'font-semibold select-none text-sonic-silver cursor-pointer',
              {
                'text-vibrant-orange decoration-2 underline underline-offset-[20px] cursor-auto':
                  tab === 'advanced',
              },
            )}
            onClick={() => setTab('advanced')}
          >
            {t('advanced')}
          </li>
          <li
            className={cn(
              'font-semibold select-none text-sonic-silver cursor-pointer',
              {
                'text-vibrant-orange decoration-2 underline underline-offset-[20px] cursor-auto':
                  tab === 'notifications',
              },
            )}
            onClick={() => setTab('notifications')}
          >
            {t('notifications.label')}
          </li>
          <li
            className={cn(
              'font-semibold select-none text-sonic-silver cursor-pointer',
              {
                'text-vibrant-orange decoration-2 underline underline-offset-[20px] cursor-auto':
                  tab === 'integrations',
              },
            )}
            onClick={() => setTab('integrations')}
          >
            {t('integrations')}
          </li>
          <li
            className={cn(
              'font-semibold select-none text-sonic-silver cursor-pointer',
              {
                'text-vibrant-orange decoration-2 underline underline-offset-[20px] cursor-auto':
                  tab === 'data',
              },
            )}
            onClick={() => setTab('data')}
          >
            {t('data')}
          </li>
          <li
            className={cn(
              'font-semibold select-none text-sonic-silver cursor-pointer',
              {
                'text-vibrant-orange decoration-2 underline underline-offset-[20px] cursor-auto':
                  tab === 'description',
              },
            )}
            onClick={() => setTab('description')}
          >
            {t('description')}
          </li>
          <li
            className={cn(
              'font-semibold select-none text-sonic-silver cursor-pointer',
              {
                'text-vibrant-orange decoration-2 underline underline-offset-[20px] cursor-auto':
                  tab === 'team',
              },
            )}
            onClick={() => setTab('team')}
          >
            {t('team')}
          </li>
        </ul>
      </div>
      <Divider />
      {tab === 'advanced' && <UserSettingsForm />}
      {tab === 'notifications' && <UserNotificationsForm />}
      {tab === 'integrations' && <IntegrationsSettingsForm />}
      {tab === 'data' && <DataSettingsForm />}
      {tab === 'description' && <DescriptionSettingsForm />}
      {tab === 'team' && <TeamTable />}
    </div>
  );
};

export default Settings;
