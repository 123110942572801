import useTranslation from 'common/hooks/useTranslation';
import React from 'react';
import { scaleLinear } from 'd3-scale';
import { format } from 'd3-format';
import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

const StackedPhasesBarChart = ({ phases }) => {
  const { t } = useTranslation('offers\\details');

  const formatNumber = (value) => {
    if (value < 1000) {
      return value.toString();
    }
    return format('.2s')(value);
  };

  const maxPhaseValue = Math.max(...Object.values(phases));
  const scale = scaleLinear().domain([0, maxPhaseValue]).range([0, 100]);

  const scaledPhases = {
    candidates: scale(phases.candidates),
    evaluation: scale(phases.evaluation),
    classification: scale(phases.classification),
    interview: scale(phases.interview),
  };

  const chartData = [
    {
      candidates: scaledPhases.candidates,
      evaluation: scaledPhases.evaluation,
      classification: scaledPhases.classification,
      interview: scaledPhases.interview,
      originalCandidates: phases.candidates,    
      originalEvaluation: phases.evaluation,    
      originalClassification: phases.classification,  
      originalInterview: phases.interview,      
    },
  ];

  return (
    <div className="flex flex-col justify-center w-full max-w-[900px] h-16 px-4">
      <h4 className="text-xs uppercase font-bold text-dark-liver">
        {t('charts.phases')}
      </h4>
      <ResponsiveContainer height='60%' width='99%'>
        <BarChart layout='vertical' data={chartData} margin={{ top: 12 }} barSize={50}>
          <XAxis hide type='number' />
          <YAxis hide type='category' />
          <Bar
            dataKey='candidates'
            stackId='phases'
            fill='#F16347'
            fillOpacity='25%'
          >
            <LabelList
              className='text-xs font-semibold'
              fill='#342E30'
              dataKey='originalCandidates'
              position='top'
              formatter={formatNumber} 
            />
          </Bar>
          <Bar
            dataKey='evaluation'
            stackId='phases'
            fill='#F16347'
            fillOpacity='50%'
          >
            <LabelList
              className='text-xs font-semibold'
              fill='#342E30'
              dataKey='originalEvaluation'  
              position='top'
              formatter={formatNumber} 
            />
          </Bar>
          <Bar
            dataKey='classification'
            stackId='phases'
            fill='#F16347'
            fillOpacity='75%'
          >
            <LabelList
              className='text-xs font-semibold'
              fill='#342E30'
              dataKey='originalClassification'  
              position='top'
              formatter={formatNumber} 
            />
          </Bar>
          <Bar
            dataKey='interview'
            stackId='phases'
            fill='#F16347'
            fillOpacity='100%'
          >
            <LabelList
              className='text-xs font-semibold'
              fill='#342E30'
              dataKey='originalInterview'  
              position='top'
              formatter={formatNumber} 
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StackedPhasesBarChart;
