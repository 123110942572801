import Loader from 'common/components/Loader';
import Toggle from 'common/components/Toggle';
import { SettingsContext } from 'common/contexts/SettingsContext';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import React, { useContext } from 'react';



import { cn } from '../../common/utils';


const UserNotificationsForm = () => {
  const toast = useToast();

  const { t } = useTranslation('settings\\index');

  const settingsContext = useContext(SettingsContext);

  const unsubscribeAllNotificationsSetting =
    settingsContext.userSettings?.find(
      (setting) => setting.name === 'unsubscribe_all_notifications',
    )?.value === 'true';

  const updateUnsubscribeAllNotificationsSetting = async (value) => {
    const settings = [
      {
        name: 'unsubscribe_all_notifications',
        value: `${value}`,
      },
    ];

    try {
      await settingsContext.updateCurrentUserSettings(settings);
      toast.newToast('positive', t('toasts.success'));
    } catch (err) {
      switch (err?.status) {
        case 403:
          toast.newToast('negative', t('toasts.forbidden'));
          break;
        default:
          toast.newToast('negative', t('something-wrong'));
      }
    }
  };

  return (
    <>
      {settingsContext.isLoadingUserSettings ? (
        <Loader />
      ) : (
        <>
          <div className='flex flex-col w-full flex-grow gap-10 mt-6 relative'>
            <div className='w-1/4 gap-6 flex flex-col'>
              <Toggle
                labelClassName={cn('text-sm -tracking-tight font-semibold pr-2')}
                isActive={unsubscribeAllNotificationsSetting}
                onClick={updateUnsubscribeAllNotificationsSetting}
                labelActive={t('notifications.unsubscribeAllNotifications')}
                labelInactive={t('notifications.unsubscribeAllNotifications')}
              />
            </div>
          </div>
          {settingsContext.isUpdatingUserSettings && <Loader />}
        </>
      )}
    </>
  );
};

export default UserNotificationsForm;
