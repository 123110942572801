import React, { useEffect, useState } from 'react';
import { cn } from 'common/utils';

const Toggle = ({
                  isActive,
                  onClick,
                  labelActive,
                  labelInactive,
                  disabled,
                  labelSide = "left",
                  className = "",
                  labelClassName = "text-sm -tracking-tight font-medium uppercase",
                  toggleClassName = ""
                }) => {
  const [active, setActive] = useState(isActive);

  const handleToggle = () => {
    if (!disabled) {
      setActive((prev) => !prev);
      onClick && onClick(!active);
    }
  };

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <div
      className={`flex items-center cursor-pointer ${disabled ? 'opacity-50 cursor-not-allowed' : ''} ${className}`}
      onClick={handleToggle}
    >
      {labelSide === "left" && (
        <span className={labelClassName}>
          {active ? labelActive : labelInactive}
        </span>
      )}
      <div
        className={cn(
          'relative flex items-center justify-center',
          'w-[54px] h-[14px] rounded-full',
          'transition-colors duration-300 ease-in-out',
          toggleClassName
        )}
        style={{
          position: 'relative',
          backgroundColor: 'transparent',
        }}
      >
        <div
          style={{
            position: 'absolute',
            width: '34px',
            height: '18px',
            backgroundColor: active
              ? 'rgba(241, 99, 71, 0.8)'
              : 'rgb(180, 180, 180)',
            borderRadius: '20px',
            opacity: '0.35',
            transition: 'background-color 0.3s ease',
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: 'absolute',
            width: '22px',
            height: '22px',
            backgroundColor: active ? '#F16347' : 'rgb(180, 180, 180)',
            borderRadius: '50%',
            top: '50%',
            left: active ? 'calc(100% - 22px)' : '2px',
            transform: 'translateY(-50%)',
            transition: 'left 0.3s ease',
            zIndex: 2,
          }}
        />
      </div>
      {labelSide === "right" && (
        <span className={labelClassName}>
          {active ? labelActive : labelInactive}
        </span>
      )}
    </div>
  );
};

export default Toggle;
