import Add from 'assets/icons/Add';
import Filter from 'assets/icons/Filter';
import Left from 'assets/icons/Left';
import Right from 'assets/icons/Right';
import Button from 'common/components/Button';
import { useNavigate } from 'react-router-dom';
import useTranslation from 'common/hooks/useTranslation';
import { setOpenOffers } from 'common/slices/offer.slice';
import { useGetOffersQuery } from 'common/slices/offerApi.slice';
import { formatDate } from 'common/utils';
import { isEmpty, isNil } from 'lodash';
import { STATE as OFFER_STATUS } from 'offers/details/components/StatusCombo';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ShowFeature } from 'split';

import { selectCurrentCompany } from '../common/slices/auth.slice';
import OffersEmptyState from './components/OffersEmptyState';
import OpenOffer from './components/OpenOffer';
import OpenOffersSkeleton from './components/OpenOffersSkeleton';
import TalentTable from './components/TalentTable';

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companySlug } = useParams();
  const currentCompany = useSelector(selectCurrentCompany);
  const { t, i18n } = useTranslation('dashboard\\index');

  const {
    data: openOffers,
    isLoading,
    refetch,
  } = useGetOffersQuery(
    {
      size: 10,
      status: OFFER_STATUS.IN_PROGRESS,
      companySlug: currentCompany?.slug,
    },
    {
      skip: !currentCompany?.slug,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (companySlug) {
      refetch();
    }
  }, [companySlug, refetch]);

  useEffect(() => {
    dispatch(setOpenOffers(openOffers?.items));
  }, [dispatch, openOffers]);

  const [hasScrolled, setScrolled] = useState(false);
  const [maxScrolled, setMaxScrolled] = useState(false);
  const [isScrollable, setScrollable] = useState(false);

  const openOffersRef = useRef(null);

  const checkScroll = () => {
    if (!openOffersRef.current) return;

    const currentScroll = openOffersRef.current.scrollLeft;
    const maxScroll =
      openOffersRef.current.scrollWidth - openOffersRef.current.clientWidth;

    setScrollable(maxScroll !== 0);
    setScrolled(currentScroll !== 0);
    setMaxScrolled(currentScroll === maxScroll);
  };

  const handleRightScroll = () => {
    openOffersRef.current?.scrollBy({
      left: 235,
      behavior: 'smooth',
    });
  };

  const handleLeftScroll = () => {
    openOffersRef.current?.scrollBy({
      left: -235,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    checkScroll();

    openOffersRef.current?.addEventListener('scroll', checkScroll);

    return () => {
      openOffersRef.current?.removeEventListener('scroll', checkScroll);
    };
  }, [openOffersRef, openOffers, window.innerWidth]);

  return (
    <div className='flex flex-col h-full w-full max-w-full p-6'>
      <div className='flex flex-col w-full mb-8'>
        <div className='flex justify-between items-center mb-4'>
          <h1 className='text-xl text-dark-liver font-semibold'>
            {t('open-offers')}
          </h1>
          <div className='flex gap-2'>
            <Button
              variant='primary'
              onClick={() => navigate(`/${companySlug}/offers/new-offer`)}
            >
              <Add size='16px' color='#FFFFFF' />
              {t('add-offer')}
            </Button>
            <Button
              variant='secondary'
              onClick={() => navigate(`/${companySlug}/offers`)}
            >
              {t('see-offers')}
              <Right size='16px' color='#8D91A0' />
            </Button>
            <Button
              size='icon'
              variant='secondary'
              onClick={handleLeftScroll}
              disabled={!isScrollable || !hasScrolled}
            >
              <Left size='16px' color='#8D91A0' />
            </Button>
            <Button
              size='icon'
              variant='secondary'
              onClick={handleRightScroll}
              disabled={!isScrollable || (isScrollable && maxScrolled)}
            >
              <Right size='16px' color='#8D91A0' />
            </Button>
          </div>
        </div>
        <div className='relative z-0 w-full max-h-[130px]'>
          <div ref={openOffersRef} className='flex gap-3 overflow-hidden'>
            {isLoading ? (
              <OpenOffersSkeleton />
            ) : isNil(openOffers) || isEmpty(openOffers?.items) ? (
              <OffersEmptyState />
            ) : (
              openOffers?.items?.map((offer) => (
                <OpenOffer
                  key={offer?.id}
                  id={offer?.id}
                  slug={offer?.slug}
                  isClosing={offer?.urgent}
                  dueDate={
                    offer?.expires_at
                      ? formatDate(offer?.expires_at, i18n)
                      : t('no-deadline')
                  }
                  position={offer?.name}
                  salaryRange={
                    offer?.salary_min + '$ - ' + offer?.salary_max + '$'
                  }
                  workType={offer?.work_type}
                  location={offer?.location}
                />
              ))
            )}
          </div>
        </div>
      </div>

      <div className='flex flex-col w-full h-full'>
        <div className='flex justify-between items-center mb-4'>
          <h1 className='text-xl text-dark-liver font-semibold'>
            {t('recent-talent')}
          </h1>
          <div className='flex gap-2'>
            <ShowFeature featureFlag='WEB_NO_ACTION_UI'>
              <Button size='icon' variant='secondary'>
                <Filter size='16px' color='#8D91A0' />
              </Button>
            </ShowFeature>
            <Button
              variant='secondary'
              onClick={() => navigate(`/${companySlug}/talent`)}
            >
              {t('see-talent')}
              <Right size='16px' color='#8D91A0' />
            </Button>
          </div>
        </div>
        <TalentTable companySlug={companySlug} />
      </div>
    </div>
  );
};

export default Dashboard;