import React from 'react';
import Inbox from 'assets/icons/Inbox';
import useTranslation from 'common/hooks/useTranslation';
import { cn } from 'common/utils';

const EmptyTestsState = () => {
  const { t } = useTranslation('offers\\new');

  return (
    <div
      className={cn(
        'flex flex-col w-full',
        'bg-white rounded-lg',
        'border border-bright-grey'
      )}
    >
      <div className={cn('flex flex-col justify-center items-center space-y-4 h-[390px] mx-6')}>
        <Inbox size={80} color="#3A4555" />
        <h2 className={cn('text-lg text-black mt-4')}>{t('disabledTitle')}</h2>
        <p className={cn('text-sm text-cool-grey max-w-md text-center')}>
          {t('noTestsDescription')}
        </p>
      </div>
    </div>
  );
};

export default EmptyTestsState;
